import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { Client } from '@microsoft/microsoft-graph-client';

const Login = () => {
  const { instance, accounts } = useMsal();
  const [users, setUsers] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate(); // Use useNavigate hook instead of useHistory
  const [userToken, setUserToken] = useState(null);

  const handleLogin = async () => {
    // instance.logoutRedirect();
    instance.clearCache();
    sessionStorage.clear();
    await instance.initialize();

    try {
      const loginResponse = await instance.loginRedirect({
            scopes: ['User.Read.All'],
            redirectUri: window.location.origin,
          });
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  useEffect(() => {
    console.log("Account Length: ",accounts);
    if (accounts.length > 0) {
      // Fetch all users when the component mounts
      fetchAllUsers();
    }
  }, [accounts]);

  const isTokenExpired = (tokenResponse) => {
    if (!tokenResponse || !tokenResponse.expiresOn) {
      return true; // Token is considered expired if there's no token or expiration time
    }
  
    const expirationTime = new Date(tokenResponse.expiresOn).getTime();
    const currentTime = Date.now();
  
    return expirationTime < currentTime;
  };

  const fetchAllUsers = async () => {
    try {
      const silentRequest = {
        scopes: ['User.Read.All'],
        account: accounts[0], // Assuming there's only one account logged in
      };
      await instance.initialize();
      const response = await instance.acquireTokenSilent(silentRequest);
      console.log("Response : ", response);
      // Create a Microsoft Graph client
      const graphClient = Client.init({
        authProvider: (done) => {
          done(null, response.accessToken);
        },
      });

      // Make a request to fetch all users
      const usersResponse = await graphClient.api('/users').get();
      setUsers(usersResponse.value);

      // Store the token in session storage
      sessionStorage.setItem('accessToken', response.accessToken);
      sessionStorage.setItem('tokenExpiresOn', response.expiresOn);
      sessionStorage.setItem('loggedInUser', response.account.name);
      if(!isTokenExpired(response)){
        // Get the user GUID
        const userOId = response.account.idTokenClaims.oid;
        const username = response.account.idTokenClaims.name;
        console.log("User Id is: ",userOId);
        sessionStorage.setItem('userId', userOId);
        sessionStorage.setItem('userDisplayName', username);
        navigate(`/post-creator`);
      }
    } catch (error) {
      // Handle errors
      console.error('Error fetching all users:', error);
    }
  };

  const toggleHover = () => {
    setIsHovered(!isHovered);
  };

  const buttonStyle = {
    padding: '18px 40px',
    fontSize: '20px',
    border: 'none',
    borderRadius: '10px',
    background: isHovered
      ? 'linear-gradient(45deg, #ff5500, #ff0088)'
      : 'linear-gradient(45deg, #3b5998, #4c70ba)',
    color: 'white',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    outline: 'none',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.logo}>Post Generator App</h1>
        {/* Add any additional header elements here */}
      </header>
      <main style={styles.main}>
        <div style={styles.content}>
          <h1 style={styles.heading}>Welcome Back!</h1>
          <p style={styles.subheading}>Click here to Login</p>
          <button
            style={buttonStyle}
            onClick={handleLogin}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
          >
            Login
          </button>
          {/* {getUserGuid() && (
            <p style={styles.guid}>User GUID: {getUserGuid()}</p>
          )} */}
        </div>
      </main>
      <footer style={styles.footer}>
        <p style={styles.footerText}>Post Generator Site Footer Information Here</p>
      </footer>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
  },
  logo: {
    fontSize: '24px',
    margin: '0',
    letterSpacing: '2px',
    textTransform: 'uppercase',
  },
  main: {
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  content: {
    maxWidth: '400px',
    padding: '40px',
    backgroundColor: '#f5f7fa',
    borderRadius: '10px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    fontSize: '36px',
    marginBottom: '15px',
    color: '#333',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  subheading: {
    fontSize: '18px',
    marginBottom: '30px',
    color: '#666',
  },
  guid: {
    marginTop: '20px',
    fontSize: '14px',
    color: '#444',
  },
  footer: {
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
  },
  footerText: {
    fontSize: '14px',
    color: '#ccc',
  },
};

export default Login;
