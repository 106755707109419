// import logo from './logo.svg';
// import './App.css';
import React from 'react';
import { MsalProvider } from '@azure/msal-react'; // Import from @azure/msal-react
import { PublicClientApplication as MSALPublicClientApplication } from '@azure/msal-browser'; // Import from @azure/msal-browser
import Login from './components/Login';
import PostCreator from './components/PostCreator';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';


const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URI, // Update with your redirect URI
  }
};

// guid of USer
// Permission 

const pca = new MSALPublicClientApplication(msalConfig); // Use MSALPublicClientApplication from @azure/msal-browser

const App = () => {
  return (
    <MsalProvider instance={pca}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/post-creator" element={<PostCreator />} />
        </Routes>
      </Router>
    </MsalProvider>
  );
};


export default App;