import React, { useState, useEffect, useRef, Component } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';
import { PublicClientApplication } from '@azure/msal-browser';
import ShareIcon from '@mui/icons-material/Share';
import SendIcon from '@mui/icons-material/Send';
import UnstyledSelectBasic from './mui/Select';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Input, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Tab, Tabs, Tooltip } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';

const PostCreator = () => {
  const [topic, setTopic] = useState('');
  const [tone, setTone] = useState('');
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Use useNavigate hook instead of useHistory
  const { instance, accounts } = useMsal();
  const [users, setUsers] = useState([]);

  // Share feature variables
  const [selectedUser, setSelectedUser] = useState('');
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [isShareloading, setIsShareLoading] = useState(false);
  const [showLogoutDropdown, setShowLogoutDropdown] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);

  const [expandTopic, setExpandTopic] = useState(false);

  const handleLogout = () => {
    // Implement logout functionality here
    sessionStorage.clear(); // Clear the session storage
    setShowLogoutDropdown(false);
    instance.clearCache();
    navigate(`/`); // Navigate to the home page
  };

  const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,
      authority: process.env.REACT_APP_AUTHORITY,
      redirectUri: process.env.REACT_APP_REDIRECT_URI, // Update with your redirect URI
    }
  };

  // Use location to get query parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = sessionStorage.getItem('userId');
  const userDisplayName = sessionStorage.getItem('userDisplayName');

  // Create a ref for the chat container
  const chatContainerRef = useRef(null);

  useEffect(() => {
    // Fetch existing chats when the component mounts
    fetchChats();
    // Scroll to bottom after initial chats are loaded
    scrollToBottom();
  }, []);

  useEffect(() => {
    // Scroll to the bottom after chats are updated
    scrollToBottom();
  }, [chats]);

  const msalInstance = new PublicClientApplication(msalConfig);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    console.log("CONSOLE LOG INDEX VALUE: ", tabIndex);
    // Fetch chats based on the selected tab
    fetchChats();
  }, [tabIndex]);

  const isTokenExpired = (tokenExpiresOn) => {
    if (!tokenExpiresOn) {
      return true; // Token is considered expired if there's no token or expiration time
    }

    const expirationTime = new Date(tokenExpiresOn).getTime();
    const currentTime = Date.now();

    return expirationTime < currentTime;
  };

  const fetchAllUsers = async () => {
    try {
      // Extract token from location props
      const userToken = sessionStorage.getItem('accessToken');
      const tokenExpiresOn = sessionStorage.getItem('tokenExpiresOn');

      if (isTokenExpired(tokenExpiresOn)) {
        sessionStorage.clear();
        navigate(`/`);
      } else {
        // Use userToken to fetch all users
        // Create a Microsoft Graph client
        const graphClient = Client.init({
          authProvider: (done) => {
            done(null, userToken);
          },
        });

        // Make a request to fetch all users
        const usersResponse = await graphClient.api('/users').get();
        const filteredUsers = usersResponse.value.filter(user => user.id != userId); 
        setUsers(filteredUsers);
        console.log("USERS: ", usersResponse);
      }
    } catch (error) {
      // Handle errors
      console.error('Error fetching all users:', error);
    }
  };

  const fetchChats = async () => {
    try {
      console.log("FETCH CHATS INDEX VALUE: ", tabIndex)
      let url;
      if (tabIndex === 0) {
        url = `${process.env.REACT_APP_GENERATE_POST_URI}/post/get?userId=${userId}`;
      } else if (tabIndex === 1) {
        url = `${process.env.REACT_APP_GENERATE_POST_URI}/post/getSharedPosts?userId=${userId}`;
      }

      const response = await fetch(url, {
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        setChats(data);
      } else {
        console.error('Failed to fetch chats');
      }
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  const handleTabChange = (event, newValue) => {    
    setTabIndex(newValue);
  };

  const handleTopicChange = (e) => {
    setTopic(e.target.value);
  };

  const handleToneChange = (e) => {
    setTone(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_GENERATE_POST_URI}/post/generate?topic=${topic}&type=${tone}&userId=${userId}&userDisplayName=${userDisplayName}`,
        {
          method: 'POST',
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (tabIndex === 0) {
           // Add the typed topic and tone as a request to the chat
          setChats([...chats, { topic, tone, post: data.post }]);
        }

        // Clear the Topic and Tone inputs
        setTopic('');
        setTone('');
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const scrollToPost = (topic, tone) => {
    console.log("Chats: {}", chats);
    const postIndex = chats.findIndex(chat => chat.topic === topic && chat.tone === tone && chat.post);
    if (postIndex !== -1 && chatContainerRef.current) {
      const postElement = chatContainerRef.current.querySelectorAll('.chatMessageContainer')[postIndex];
      if (postElement) {
        postElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };


  const handleShareClick = (postId, index) => {
    setSelectedPostId(postId);
    setShowShareDropdown(index); // Set the index of the clicked message
    setIsUserSelected(false);
  };

  const handleUserSelect = (selectedUserId) => {
    // Find the user object from the users array based on the selectedUserId
    const selectedUserObj = users.find(user => user.id === selectedUserId);

    // Set the selected user display name
    setSelectedUser(selectedUserObj.displayName);

    // Set the selected user ID for the share API call
    setSelectedUserId(selectedUserId);

    // Set isUserSelected to true
    setIsUserSelected(true);
  };

  const handleSubmitShare = async () => {
    setIsShareLoading(true);
    if (!selectedUser) {
      // Handle case where no user is selected
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_GENERATE_POST_URI}/post/share?userDisplayName=${selectedUser}&postId=${selectedPostId}&userId=${selectedUserId}`,
        {
          method: 'POST',
        }
      );

      if (response.ok) {
        // Handle success scenario, maybe show a success message
        console.log('Post shared successfully');
      } else {
        console.error('Failed to share post');
      }
    } catch (error) {
      console.error('Error sharing post:', error);
    }
    fetchChats();

    setShowShareDropdown(false);
    setIsShareLoading(false);
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h4 style={styles.logo}>Post Generator App</h4>
        <Tooltip>
          <div style={styles.profileIconContainer} onClick={() => setShowLogoutDropdown(!showLogoutDropdown)}>
            <Avatar sx={{ width: 30, height: 30 }} alt={sessionStorage.getItem("loggedInUser")} src="path_to_user_avatar" style={styles.avatar} />
            <span style={styles.userName}>{sessionStorage.getItem("loggedInUser")}</span>
            {showLogoutDropdown && (
              <Tooltip title="Logout">
                <LogoutIcon onClick={handleLogout} />
              </Tooltip>
            )}
          </div>

        </Tooltip>

      </header>
      {/* <MenuItem value={10}>Ten</MenuItem> */}

      <main style={styles.main}>

        <div style={styles.content}>
          <div>
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="History" />
              <Tab label="Shared Posts" />
            </Tabs>

            <div style={styles.topicToneList}>
              <div style={styles.topicToneContainer}>
                {chats.map((chat, index) => (
                  <div key={index} style={styles.topicToneItem} onClick={() => scrollToPost(chat.topic, chat.tone)}>
                    <p>
                    <strong>Topic:</strong> {chat.topic.length > 100 && !expandTopic ? `${chat.topic.slice(0, 100)}...`: chat.topic}
                    </p>
                    {chat.topic.length > 100 ? (!expandTopic ? <ExpandMoreOutlined title="Expand Topic" onClick={() => setExpandTopic(true)}></ExpandMoreOutlined> : <ExpandLessOutlined onClick={() => setExpandTopic(false)}></ExpandLessOutlined>): ""}
                    <p>
                      <strong>Tone:</strong> {chat.tone || 'Not specified'}
                    </p>
                    {/* Display user icon for each user */}
                    {chat.sharedWithUsers && chat.sharedWithUsers.map((user) => (
                      <Tooltip key={user.id} title={user.userDisplayName}>
                        {user.read ? <PersonIcon style={{ color: 'grey' }} /> : <PersonIcon style={{ color: 'blue' }} />}
                      </Tooltip>
                    ))}
                    {
                      chat.userDisplayName && 
                      <Tooltip key={chat.id} title={chat.userDisplayName}>
                        {/* {console.log("USER ISSSS: ", user)} */}
                        <PersonIcon style={{ color: 'blue' }} />
                        {/* {user.userDisplayName} */}
                      </Tooltip>
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div style={styles.chatWindow} ref={chatContainerRef}>
            <h3 style={styles.chatHeading}>Chat</h3>
            <div>
              {chats.map((chatItem, index) => (
                <div key={index} style={styles.chatMessageContainer} className="chatMessageContainer">
                  <p style={styles.chatRequest}>{`Topic: ${chatItem.topic}, Tone: ${chatItem.tone || 'Not specified'}`}</p>
                  <p style={styles.chatResponse}>{`${chatItem.post || 'Generating...'}`}</p>

                  {showShareDropdown === index ? (
                    <div>
                      <div style={styles.selectMenu}>
                        <UnstyledSelectBasic options={users} onChange={handleUserSelect} />

                      </div>
                      {isUserSelected && !isShareloading && <SendIcon style={styles.sendIcon} onClick={handleSubmitShare} />}
                      {isShareloading && <CachedIcon style={styles.shareLoadIcon}></CachedIcon>}
                    </div>
                  ) : (
                    <ShareIcon onClick={() => handleShareClick(chatItem.postId, index)} style={styles.shareIcon} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
      <form onSubmit={handleSubmit} style={styles.form}>
        <label style={styles.label}>
          <input
            type="text"
            value={topic}
            onChange={handleTopicChange}
            style={styles.input}
            placeholder="Enter Topic"
          />
        </label>
        <label style={styles.label}>
          <input
            type="text"
            value={tone}
            onChange={handleToneChange}
            style={styles.input}
            placeholder="Enter Tone"
          />
        </label>
        <button type="submit" style={styles.button} disabled={loading}>
          {loading ? 'Loading...' : 'Generate Post'}
        </button>
      </form>
      <footer style={styles.footer}>
        <h2 style={styles.footerText}>Post Generator Site Footer Information Here</h2>
      </footer>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Arial, sans-serif',
    height: '100vh'
  },
  logo: {
    fontSize: '32px',
    margin: '0',
    letterSpacing: '2px',
    textTransform: 'uppercase',
  },
  main: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row', // Change to row to align tabs and content side by side
    justifyContent: 'flex-start', // Align items to the start of the container
    alignItems: 'flex-start', // Align items to the top of the container
    textAlign: 'center',
    boxSizing: 'border-box',
    marginTop: '2px',
    overflow: 'hidden', // Prevent main from overflowing
  },
  header: {
    backgroundColor: '#333',
    color: 'white',
    padding: '5px',
    textAlign: 'center',
    height: '30px',
    display: 'flex',
    justifyContent: 'space-between', // Align items horizontally
    alignItems: 'center', // Align items vertically
  },
  footer: {
    backgroundColor: '#333',
    color: 'white',
    padding: '9px',
    textAlign: 'center',
    // flexShrink: 0, // Ensure footer doesn't shrink
    height: '20px', // Set a fixed height for the footer
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    margin: '0 auto',
  },
  topicToneList: {
    flex: '.72',
    padding: '0px',
    borderRight: '1px solid #ccc',
    maxHeight: 'calc(100vh - 180px)',
    overflowY: 'auto',
    marginTop: '10px',
    marginLeft: '10px', // Remove the default margin
  },
  topicToneContainer: {
    maxHeight: 'calc(100vh - 180px)',
    overflowY: 'auto',
    marginLeft: '0', // Remove the default margin
  },
  topicToneItem: {
    marginBottom: '20px',
    padding: '10px',
    backgroundColor: '#f5f5f5',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginRight: '10px',
    maxWidth: '200px',
  },
  chatWindow: {
    flex: '3',
    padding: '20px',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 180px)',
    position: 'relative',
  },
  chatMessageContainer: {
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'flex-start', // Align items to the left
  },
  chatRequest: {
    backgroundColor: '#f5f7fa',
    color: '#333',
    padding: '8px',
    borderRadius: '8px',
    marginBottom: '5px',
    maxWidth: '60%',
    wordWrap: 'break-word',
    alignSelf: 'flex-start',
  },
  chatResponse: {
    backgroundColor: '#0078d4',
    color: 'white',
    padding: '8px',
    borderRadius: '8px',
    marginBottom: '5px',
    maxWidth: '60%',
    wordWrap: 'break-word',
    alignSelf: 'flex-end', // Align response to the right
    position: 'relative',
  },
  shareIcon: {
    cursor: 'pointer',
    color: '#0078d4',
    position: 'absolute',
    left: '320px', // Adjust as needed to position the icon to the left of the response with some margin
    top: '63%', // Align the icon vertically in the middle
    transform: 'translateY(-50%)', // Adjust for vertical centering
  },
  sendIcon: {
    cursor: 'pointer',
    color: '#0078d4',
    position: 'absolute',
    left: '320px', // Adjust as needed to position the icon to the left of the response with some margin
    top: '63%', // Align the icon vertically in the middle
    transform: 'translateY(-50%)', // Adjust for vertical centering
  },
  shareLoadIcon: {
    color: '#0078d4',
    position: 'absolute',
    left: '135px', // Adjust as needed to position the icon to the left of the response with some margin
    top: '63%', // Align the icon vertically in the middle
    transform: 'translateY(-50%)', // Adjust for vertical centering
  },
  selectMenu: {
    position: 'absolute',
    top: '63%', // Align the icon vertically in the middle
    transform: 'translateY(-50%)', // Adjust for vertical centering
    left: "165px"
  },
  footerText: {
    fontSize: '14px',
    color: '#ccc',
  },
  chatHeading: {
    fontSize: '18px',
    marginBottom: '10px',
    color: '#333',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '5px',
    marginBottom: '5px',
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: '800px'
  },
  label: {
    marginRight: '35px',
    marginTop: '10px'
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '100%',
  },
  button: {
    padding: '12px',
    fontSize: '16px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#0078d4',
    color: 'white',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    outline: 'none',
  },
  profileIconContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '10px', // Add margin to separate from the logo
  },
  avatar: {
    marginRight: '5px', // Add margin between avatar and name
  },
  userName: {
    marginLeft: '5px', // Add margin between name and avatar
    marginRight: '15px'
  }
};


export default PostCreator;
